import PropTypes from "prop-types";
import Collapsible from "./component/Collapsible";
import CollapseArrow from "./component/CollapseArrow";
import cn from "Utils/cn";
import useSidebarStore from "./hooks/useSidebarStore";
import SidenavList from "./SidenavList";
import useWindowScreen from "Utils/useWindowScreen";

function SidenavCollapse({
  icon,
  name,
  children,
  active,
  noCollapse,
  open,
  onClick = () => {},
  ...rest
}) {
  const { miniSidebar, toggleMiniSidebar } = useSidebarStore();
  const { isMobile } = useWindowScreen();

  return (
    <>
      <div
        className={cn(
          "flex items-center gap-5 w-full hover:bg-[#e1f5e1] cursor-pointer hover:font-semibold rounded-sm hover:shadow-sm px-5 py-3",
          active && "bg-[#e1f5e1] font-semibold",
        )}
        onClick={() => {
          onClick();
          if (isMobile && noCollapse) toggleMiniSidebar();
        }}
        {...rest}
      >
        {icon}
        {!miniSidebar && <p>{name}</p>}

        {!noCollapse && !miniSidebar && <CollapseArrow open={open} />}
      </div>

      {children && (
        <Collapsible open={open}>
          <SidenavList>{children}</SidenavList>
        </Collapsible>
      )}
    </>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
  noCollapse: false,
  children: false,
  open: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  active: PropTypes.bool,
  noCollapse: PropTypes.bool,
  open: PropTypes.bool,
};

export default SidenavCollapse;
