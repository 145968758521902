import { useEffect, useState } from "react";
import { MOBILE_SCREEN_WIDTH } from "Utils/constants";

const useWindowScreen = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { windowWidth, isMobile: windowWidth <= MOBILE_SCREEN_WIDTH };
};

export default useWindowScreen;
