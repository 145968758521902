import { create } from "zustand";

const useSidebarStore = create((set) => ({
  miniSidebar: false,
  setMiniSidebar: (value) => set({ miniSidebar: value }),
  toggleMiniSidebar: () =>
    set((state) => ({ miniSidebar: !state.miniSidebar })),
}));

export default useSidebarStore;
