import { Route } from "react-router-dom";

import SignUpScreen from "../Screens/SignUpScreen";
import Login from "../Screens/Login";
import ForgetPassword from "../Screens/ForgetPassword/ForgetPassword";
import ResendVerifyEmail from "../Screens/VerifyEmail/ResendVerifyEmail";
import VerifyEmail from "../Screens/VerifyEmail/VerifyEmail";
import ResetPassword from "../Screens/ForgetPassword/ResetPassword";
import { navigationRoutes } from "Utils/navigationRoutes";

const sharedRoutes = [
  <Route path={navigationRoutes.SIGNUP} element={<SignUpScreen />} />,
  <Route
    path={`${navigationRoutes.SHORTNAME}${navigationRoutes.LOGIN}`}
    element={<Login />}
  />,
  <Route
    path={`${navigationRoutes.SHORTNAME}${navigationRoutes.FORGET_PASSWORD}`}
    element={<ForgetPassword />}
  />,
  <Route
    path={`${navigationRoutes.SHORTNAME}${navigationRoutes.RESET_PASSWORD}`}
    element={<ResetPassword />}
  />,
  <Route
    path={`${navigationRoutes.SHORTNAME}${navigationRoutes.VERIFY_EMAIL}`}
    element={<VerifyEmail />}
  />,
  <Route
    path={`${navigationRoutes.SHORTNAME}${navigationRoutes.RESEND_VERIFY_EMAIL}`}
    element={<ResendVerifyEmail />}
  />,
];

export default sharedRoutes;
