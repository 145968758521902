/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const AppSlice = createSlice({
  name: "appslice",
  initialState: {
    registrationSuccess: false,
    shortName: null,
  },
  reducers: {
    setRegistration: (state, action) => {
      state.shortName = action.payload.data;
      state.registrationSuccess = action.payload.success;
    },
  },
});

export const { setRegistration } = AppSlice.actions;
export default AppSlice.reducer;
