import { FiSettings } from "react-icons/fi";

import SchoolProfile from "Screens/AdmissionScreen/Settings/SchoolProfile";
import BankControl from "Screens/AdmissionScreen/Settings/BankControl";
import SessionControl from "Screens/AdmissionScreen/Settings/SessionControl";
import Section from "Screens/AdmissionScreen/Settings/Section";
import Category from "Screens/AdmissionScreen/Settings/Category";
import AttachmentDocument from "Screens/AdmissionScreen/Settings/AttachmentDocument";
import Classes from "Screens/AdmissionScreen/Classes";
import ClassList from "Screens/AdmissionScreen/Classes/View/ClassList";
import Profile from "Screens/Profile/Profile";

import { Icon } from "Routes/utils/component";

const generalRoutesList = [
  {
    type: "collapse",
    name: "General Settings",
    key: "general-settings",
    icon: <Icon Name={FiSettings} />,
    sidenav: true,
    collapse: [
      {
        name: "School Profile",
        key: "school-profile",
        route: "general-settings/school-profile",
        component: <SchoolProfile />,
      },
      {
        name: "Bank Control",
        key: "bank",
        route: "general-settings/bank",
        component: <BankControl />,
      },
      {
        name: "Session Configuration",
        key: "session",
        route: "general-settings/session",
        component: <SessionControl />,
      },

      {
        name: "Section",
        key: "section",
        route: "general-settings/section",
        component: <Section />,
      },

      {
        name: "Category",
        key: "category",
        route: "general-settings/category",
        component: <Category />,
      },

      {
        name: "Attachment Document",
        key: "attachment-document",
        route: "general-settings/attachment-document",
        component: <AttachmentDocument />,
      },

      {
        name: "Classes",
        key: "class",
        route: "general-settings/class",
        component: <Classes />,
      },
    ],
  },

  {
    type: "collapse",
    name: "classList",
    key: `class/all`,
    route: "general-settings/class/all",
    noCollapse: true,
    component: <ClassList />,
  },

  {
    type: "collapse",
    name: "profile",
    key: `profile`,
    route: "general-settings/profile",
    noCollapse: true,
    component: <Profile />,
  },
];

export default generalRoutesList;
