import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { IoDocumentTextOutline } from "react-icons/io5";
import Skeleton from "react-loading-skeleton";

import MainLayout from "Component/Layout/MainLayout";
import Button from "Component/Forms/Button";
import Avatar from "Component/Avatar";
import cn from "Utils/cn";
import ConfirmBox from "Component/ConfirmBox";
import BackButton from "Component/BackButton";
import capitalizeFirstLetter from "../../../../Utils/capitalizeFirstLetter";
import StatusIdentifier from "Component/StatusIdentifier";
import useUpdateApplicantStatus from "../hooks/useUpdateApplicantStatus";
import { statusConstant } from "Utils/constants";
import { admissionFlattenRoutes } from "Routes/admissionRoutes";

const ApplicantDetails = () => {
  const params = useParams();

  const {
    openApprove,
    setOpenApprove,
    openReject,
    setOpenReject,
    handleUpdateStatus,
    isFetching,
    isLoading,
    isLoadingUpdateStatus,
    data,
  } = useUpdateApplicantStatus({ id: params.id });

  return (
    <MainLayout isLoading={isLoading}>
      <BackButton
        navigateTo={`/${params.shortName}/${admissionFlattenRoutes.applicants}`}
      />
      <div className="flex flex-col gap-5 sm:gap-3">
        <div className="w-full flex flex-col bg-white sm:bg-inherit relative">
          <div className="h-[48px] w-full bg-[#052F5E] font-bold text-white py-6 pl-40 sm:pl-0 flex sm:justify-center items-center">
            <span>Aplicants Information</span>
          </div>

          <div className="h-[75%] absolute sm:relative top-6 sm:top-3 left-6 sm:left-0 flex flex-col sm:gap-2 items-center justify-between ">
            <Avatar
              image={data?.photo}
              className={"h-[100px] sm:h-[200px] sm:w-[200px] w-[100px] "}
            />

            {!isFetching ? (
              <StatusIdentifier status={data?.admissionStatus} />
            ) : (
              <Skeleton
                count={1}
                height={30}
                containerClassName="w-[100px] sm:w-[200px]"
              />
            )}
          </div>

          <div className="flex sm:flex-col sm:gap-3 justify-between text-[12px] sm:items-center border-x-1 border-b-1 sm:border-none py-10 sm:py-0 sm:mt-5 pr-12 sm:pr-0 sm:pl-0 pl-40 h-full ">
            <div className="flex flex-col gap-2 sm:bg-white sm:w-full sm:py-3 sm:px-5">
              <Row title={"Application ID"} text={data?.applicantNumber} />
              <Row
                title={"Name"}
                text={`${data?.firstName} ${data?.middleName || ""} ${data?.lastName}`}
              />
              <Row
                title={"D.O.B"}
                text={dayjs(data?.dob).format("dddd, MMM, YYYY")}
              />
              <Row
                title={"Gender"}
                text={capitalizeFirstLetter(data?.gender)}
              />
              <Row
                title={"Address"}
                text={capitalizeFirstLetter(data?.address || "")}
              />
            </div>

            <div className="flex flex-col gap-2 sm:bg-white sm:w-full sm:py-3 sm:px-5">
              <p className="text-[12px] font-bold sm:text-[16px]">
                Guardian Information
              </p>
              <Row title={"Name"} text={data?.parentOrGuardianInfo?.fullName} />
              <Row
                title={"Phone Number"}
                text={data?.parentOrGuardianInfo?.phone}
              />
              <Row title={"Email"} text={data?.parentOrGuardianInfo?.email} />
            </div>
          </div>
        </div>

        <div className="py-5 px-10 sm:px-0 sm:flex sm:justify-center bg-white">
          <Row
            title={"Health Challenges"}
            text={data?.medicalInfo || "None"}
            textClass={"font-bold"}
            className={"sm:border-none"}
          />
        </div>

        <div className="py-5 sm:py-3 px-10 sm:px-5 bg-white flex sm:flex-col justify-between flex-wrap">
          <Row title={"Section"} text={data?.section?.name} />
          <Row title={"Class"} text={data?.class?.name} />
          <Row title={"Category"} text={data?.category?.name || "None"} />
          <Row title={"Payment Status"}>
            <StatusIdentifier status={data?.payment?.status} />
          </Row>
        </div>

        {data?.supportingDocuments?.length > 0 && (
          <div className=" flex flex-col gap-3">
            <p className="text-[16px] font-bold sm:text-[16px] py-2 sm:py-3 px-10 sm:px-5 bg-white">
              Documents
            </p>

            <div className="flex flex-wrap gap-24 sm:gap-3">
              {data?.supportingDocuments.map((document, index) => (
                <div key={index} className="flex flex-col">
                  <p className="text-[12px] ml-5">{document?.name}</p>
                  <a href={document?.url} target="_blank" rel="noreferrer">
                    <IoDocumentTextOutline className="text-[100px]" />
                  </a>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="flex gap-5 sm:gap-0 sm:justify-between">
          <Button
            className="w-[121px] sm:w-[48%] bg-[#8D0909] py-2"
            title="Decline"
            handleSubmit={() => setOpenReject(true)}
          />
          {statusConstant.admitted === data?.admissionStatus &&
          data?.admissionLetter !== undefined ? (
            <a
              target="_blank"
              rel="noreferrer"
              href={data?.admissionLetter?.letterUrl}
            >
              <Button
                variant="outline"
                className="w-auto  py-2"
                title="View Admission Letter"
              />
            </a>
          ) : (
            <Button
              className="w-[121px] sm:w-[48%] bg-[#248D09] py-2"
              title="Admit"
              handleSubmit={() => setOpenApprove(true)}
            />
          )}
        </div>
      </div>

      {openApprove && (
        <ConfirmBox
          openConfirmBox={openApprove}
          setOpenConfirmBox={setOpenApprove}
          title="Approve Appilcation"
          isLoading={isLoadingUpdateStatus}
          handleConfirm={() => handleUpdateStatus("approve")}
        />
      )}

      {openReject && (
        <ConfirmBox
          openConfirmBox={openReject}
          setOpenConfirmBox={setOpenReject}
          title="Reject Appilcation"
          isLoading={isLoadingUpdateStatus}
          handleConfirm={() => handleUpdateStatus("decline")}
        />
      )}
    </MainLayout>
  );
};

export default ApplicantDetails;

const Row = ({ title, text, className, titleClass, textClass, children }) => {
  return (
    <div
      className={cn(
        "flex gap-4 text-[12px] sm:text-[12px] sm:py-[6px] sm:border-b-1 items-center",
        className,
      )}
    >
      <p className={cn(titleClass)}>{title}:</p>
      <p className={cn(textClass)}>{text}</p>

      {children}
    </div>
  );
};
