import { Route } from "react-router-dom";

const toCamelCase = (str) => {
  return str.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
};
export const getRoutes = (allRoutes) =>
  allRoutes.map((route) => {
    if (route.collapse) {
      return getRoutes(route.collapse);
    }

    if (route.route) {
      return (
        <Route
          exact
          path={route.route}
          element={route.component}
          key={route.key}
        />
      );
    }

    return null;
  });

export const flattenRoutes = (list) => {
  const flattened = {};

  list.forEach((item) => {
    // Skip items with a colon (e.g., dynamic routes like `applicants/:id`)
    if (item.key.includes(":")) return;

    if (item.noCollapse) {
      const key = toCamelCase(item.key);
      flattened[key] = item.route;
    } else if (item.collapse && item.collapse.length > 0) {
      item.collapse.forEach((subItem) => {
        if (subItem.key.includes(":")) return; // Skip sub-items with a colon as well
        const key = toCamelCase(subItem.key);
        flattened[key] = subItem.route;
      });
    }
  });

  return flattened;
};
