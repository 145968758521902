/* eslint-disable no-unneeded-ternary */
import { useNavigate, useParams } from "react-router";

import Switch from "../Switch";
import useToggleActiveAppStore from "../../hooks/useToggleActiveAppStore";
import { currentApp } from "../../Utils/constants";
import { admissionFlattenRoutes } from "Routes/admissionRoutes";
import { academicFlattenRoutes } from "Routes/academicRoutes";

const Toggle = () => {
  const { activeApp, setActiveApp } = useToggleActiveAppStore();
  const navigate = useNavigate();
  const { shortName } = useParams();

  return (
    <div className={`flex items-center gap-3 sm:gap-1`}>
      {activeApp === currentApp.ACADEMICS ? (
        <p className="text-[14px] sm:text-[11px] w-24 sm:w-16">Academics</p>
      ) : (
        <p className={"w-24 sm:w-16"} />
      )}

      <Switch.Root
        checked={activeApp === currentApp.ADMISSION ? true : false}
        className="bg-[#0064c1] data-[state=checked]:bg-[#004586]"
        onCheckedChange={(e) => {
          setActiveApp(e);
          if (!e) navigate(`/${shortName}/${academicFlattenRoutes.dashboard}`);
          if (e) navigate(`/${shortName}/${admissionFlattenRoutes.dashboard}`);
        }}
      >
        <Switch.Thumb />
      </Switch.Root>
      {activeApp === currentApp.ADMISSION ? (
        <p className="text-[14px] sm:text-[11px] w-24 sm:w-16">Admissions</p>
      ) : (
        <p className={"w-24 sm:w-16"} />
      )}
    </div>
  );
};

export default Toggle;
