export const tags = {
  SESSION_CONTROL_TAG: "sessionControls",
  TEMPLATE_CONTROL_TAG: "templateControls",
  PAYMENT_CONTROL_TAG: "paymentControls",
  BANK_CONTROL_TAG: "bankControls",
  MY_PROFILE_CONTROL_TAG: "myprofile",
  SCHOOL_PROFILE_CONTROL_TAG: "schoolprofile",
  SCHOOL_SECTIONS: "schoolsections",
  SCHOOL_CATEGORIES: "schoolcategories",
  SCHOOL_CLASS: "schoolclass",
  SCHOOL_SUPPORTING_DOCUMENT: "schoolsupportingdocument",
  SCHOOL_APPLICATIONS: "schoolapplications",
  SCHOOL_APPLICANTS: "schoolapplicants",
  ADMISSION_ENTRY_REQUIREMENT: "admissionentryrequirement",
  ATTACHMENT: "attachment",
};

export const userRole = {
  ADMIN: "super_admin",
  PARENT: "parent",
};

export const IMAGE_SIZE = 300 * 1024;

export const roles = {
  SUPER_ADMIN: "super_admin",
  PARENT: "parent",
};

export const currentApp = {
  ACADEMICS: "academics",
  ADMISSION: "admissions",
};

export const admissionTemplateTabs = ["Template 1"];

export const countryList = [{ value: "nigeria", label: "Nigeria" }];
export const localGovernmentList = [
  { value: "yola north", label: "Yola North" },
];
export const stateList = [{ value: "adamawa", label: "Adamawa" }];

export const genderList = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
];

export const paymentOptions = [
  { value: "offline", label: "Offline" },
  { value: "online", label: "Online" },
];

export const CURRENCY_SYMBOL = "₦";

export const sessionList = [
  { value: "2023/2024", label: "2023/2024" },
  { value: "2024/2025", label: "2024/2025" },
  { value: "2025/2026", label: "2025/2026" },
];

export const statusConstant = {
  pending: "pending",
  admitted: "admitted",
  successful: "successful",
  declined: "declined",
  failed: "failed",
  published: "published",
  notPublish: "notPublish",
};

export const filesAllowed = ["jpeg", "png", "jpg"];

export const MOBILE_SCREEN_WIDTH = 639;
