import { RxDashboard } from "react-icons/rx";

import { currentApp } from "Utils/constants";
import AcademicsHome from "Screens/AcademicScreens/Home";
import { Icon } from "Routes/utils/component";

const route = currentApp.ACADEMICS;

const academicRoutesList = [
  {
    type: "collapse",
    name: "Dashboard",
    key: `dashboard`,
    route: `${route}/dashboard`,
    icon: <Icon Name={RxDashboard} />,
    noCollapse: true,
    sidenav: true,
    component: <AcademicsHome />,
  },
];

export default academicRoutesList;
