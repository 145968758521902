/* eslint-disable react-hooks/exhaustive-deps */
import { useLoginMutation } from "../../Network/Auth/auth";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { admissionFlattenRoutes } from "Routes/admissionRoutes";
import useToggleActiveAppStore from "hooks/useToggleActiveAppStore";

const useLogin = () => {
  const navigate = useNavigate();
  const { shortName } = useParams();
  const { setActiveApp } = useToggleActiveAppStore();

  const [login, { isLoading, isError, error, isSuccess, data }] =
    useLoginMutation();

  useEffect(() => {
    // reset();
    if (isError) {
      toast.error(error?.data?.message);
    }

    if (isSuccess) {
      localStorage.setItem("school_token", data.token);
      localStorage.setItem(
        "school_decode_token",
        JSON.stringify({
          id: data.result?.user?._id,
          shortName: data.result?.user?.schoolShortName,
          schoolId: data.result?.school?._id,
        }),
      );
      if (!data.result?.user?.emailVerified) {
        toast.error("Your account is not verified");
        navigate(`/${shortName}/resend-verify-email`);
        return;
      }
      setActiveApp(true);
      navigate(`/${shortName}/${admissionFlattenRoutes.dashboard}`);
    }
  }, [isError, isSuccess]);

  const handleLogin = (values) => {
    login(values);
  };

  return { loading: isLoading, handleLogin };
};

export default useLogin;
