import { Route } from "react-router-dom";
import { navigationRoutes } from "Utils/navigationRoutes";
import admissionRoutesList from "./admissionRoutesList";
import { getRoutes, flattenRoutes } from "Routes/utils";

export const admissionRoutes = (
  <Route path={navigationRoutes.SHORTNAME}>
    {getRoutes(admissionRoutesList)}
  </Route>
);

export const admissionFlattenRoutes = flattenRoutes(admissionRoutesList);

console.log(admissionFlattenRoutes);
