import { useNavigate } from "react-router";

import MainLayout from "Component/Layout/MainLayout";
import BodyHeaderLayout from "Component/Layout/BodyHeaderLayout";
import Button from "Component/Forms/Button";
import { IoAddCircleOutline } from "react-icons/io5";
import CustomSearch from "Component/CustomSearch";
import { useParams } from "react-router-dom";
import ApplicantDeskTopView from "./View/ApplicantDeskTopView";
import ApplicantMobileView from "./View/ApplicantMobileView";
import { useGetApplicantsQuery } from "Network/applicants";
import useGetApplicantsFilters from "./hooks/useGetApplicantsFilters";
import ConfirmDelete from "Component/ConfirmDelete";
import EditApplicant from "./Manage/EditApplicant";
import Pagination from "Component/Pagination/Pagination";
import useApplicantStore from "./hooks/useApplicantStore";
import useDeleteApplicant from "./hooks/useDeleteApplicant";
import { admissionFlattenRoutes } from "Routes/admissionRoutes";

const Applicant = () => {
  const params = useParams();
  const navigate = useNavigate();

  const {
    page,
    search,
    openConfirmDelete,
    setOpenConfirmDelete,
    openEdit,
    setOpenEdit,
    setPage,
    handleSearch,
  } = useGetApplicantsFilters();

  const applicantsState = useGetApplicantsQuery({ page, search });
  const { applicants } = useApplicantStore();

  const { handleDeleteApplicant, deleteApplicantState } = useDeleteApplicant({
    id: applicants?._id,
  });

  return (
    <MainLayout>
      <div>
        <BodyHeaderLayout title={"Manage Applicants  "}>
          <div className="flex gap-3 sm:w-[100%]">
            <Button
              Icon={IoAddCircleOutline}
              withIcon
              className="w-fit  text-[12px] h-[41px] "
              title="Add Applicant"
              handleSubmit={() =>
                navigate(
                  `/${params.shortName}/${admissionFlattenRoutes.createApplicant}`,
                )
              }
            />
          </div>
        </BodyHeaderLayout>
        <div className="flex justify-between sm:flex-col mb-[20px] bg-white p-3">
          <CustomSearch
            className="w-[40%] sm:w-[100%] text-[14px]  "
            placeholderText="Search by name, section, class or admission status"
            handleChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <ApplicantDeskTopView applicantsState={applicantsState} />
        <ApplicantMobileView applicantsState={applicantsState} />

        {!applicantsState.isFetching && applicantsState?.data?.length !== 0 && (
          <div className="flex w-full justify-end mt-2">
            <Pagination
              onPageChange={setPage}
              pageTotal={applicantsState?.data?.totalPages}
              initialPage={applicantsState?.data?.currentPage}
            />
          </div>
        )}
      </div>

      {openConfirmDelete && (
        <ConfirmDelete
          openConfirmDelete={openConfirmDelete}
          setOpenConfirmDelete={setOpenConfirmDelete}
          title="Applicant"
          deleteName={applicants?.firstName}
          handleDelete={handleDeleteApplicant}
          isLoading={deleteApplicantState.isLoading}
        />
      )}

      {openEdit && <EditApplicant open={openEdit} setOpen={setOpenEdit} />}
    </MainLayout>
  );
};

export default Applicant;
