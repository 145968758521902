import MainLayout from "Component/Layout/MainLayout";
import Button from "Component/Forms/Button";
import Select from "Component/Forms/Select";
import BodyHeaderLayout from "Component/Layout/BodyHeaderLayout";
import { useNavigate } from "react-router";
import Input, { InputNoFormik } from "Component/Forms/Input";
import { Formik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { useParams } from "react-router-dom";
import CreateDoc from "./CreateDoc";
import CreateRequirement from "./CreateRequirement";
import useCreateApplication from "../hooks/useCreateApplication";
import { admissionFlattenRoutes } from "Routes/admissionRoutes";
import { transformSelectOption, transformClassOption } from "Utils/transform";

const CreateApplications = () => {
  const params = useParams();

  const {
    sections,
    selectedSection,

    setSelectedSection,
    classes,
    selectedClasses,
    setSelectedClasses,

    documents,
    selectedDocument,
    setSelectedDocument,

    selectedPaymentMode,
    setSelectedPaymentMode,

    selectedHasEntranceExam,
    setSelectedHasEntranceExam,

    entryRequirements,
    selectedRequirement,
    setSelectedRequirement,

    session,
    applicationState,
    handleSubmit,

    selectedAttachment,
    setSelectedAttachment,
    attachments,
  } = useCreateApplication();

  const [openCreateDoc, setOpenCreateDoc] = useState(false);

  const [openCreateEntryRequirement, setOpenCreateEntryRequirement] =
    useState(false);

  const navigate = useNavigate();

  const initialValues = {
    description: "",
    session,
    schoolId: "",
    sectionId: "",
    classes: [],
    supportingDocuments: [],
    entryRequirements: [],
    attachments: [],
    paymentMode: "",
    fee: 0,
    hasEntranceExam: false,
    entranceExamDate: "",
    startDate: "",
    endDate: "",
    classResumeDate: "",
    isPublished: true,
  };

  const validationSchema = Yup.object().shape({
    description: Yup.string().required("Description is required"),
    sectionId: Yup.string().required("Section is required"),
    classes: Yup.array().required("Class is required"),
    supportingDocuments: Yup.array().required(
      "Supporting Documents is required",
    ),
    entryRequirements: Yup.array().required("Entry Requirements is required"),
    paymentMode: Yup.string().required("Payment Mode is required"),

    classResumeDate: Yup.string().required("Resumption Date is required"),
    startDate: Yup.string().required("Start Date is required"),
    endDate: Yup.string().required("End Date is required"),
  });

  return (
    <MainLayout>
      <div className="flex flex-col gap-3 mt-[10px]">
        <BodyHeaderLayout title={"Set Applications"}>
          <div className="flex gap-3 sm:w-[100%]">
            <Button
              className="w-fit sm:w-[50%] text-[12px] h-[41px] "
              title="View Applications"
              handleSubmit={() =>
                navigate(
                  `/${params.shortName}/${admissionFlattenRoutes.applications}`,
                )
              }
            />
          </div>
        </BodyHeaderLayout>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <div className="flex justify-between sm:flex-col gap-5 sm:gap-4 flex-wrap border-1 px-6 py-6 bg-white">
              <Select
                label="Section"
                className={`${props.errors?.sectionId && "!border-[red] "}`}
                mainContainerStyle="sm:w-[100%] w-[30%] text-[14px]"
                data={transformSelectOption(sections?.data)}
                value={selectedSection}
                isLoading={sections.isFetching}
                onChangeOption={(x) => {
                  props.setFieldValue("sectionId", x?.value);
                  setSelectedSection(x);
                }}
              />

              <Select
                label="Classes"
                className={`${props.errors?.sectionId && "!border-[red] "}`}
                mainContainerStyle="sm:w-[100%] w-[30%] text-[14px]"
                selectMultiple
                data={transformClassOption(classes?.data)}
                value={selectedClasses}
                isLoading={classes.isFetching}
                onChangeOption={(x) => {
                  props.setFieldValue(
                    "classes",
                    x.map((eachClass) => eachClass?.value),
                  );
                  setSelectedClasses(x);
                }}
              />

              <div className="flex flex-col w-[30%] sm:w-[100%] gap-1 items-end">
                <Select
                  label="Supporting document"
                  className={`${props.errors?.sectionId && "!border-[red] "}`}
                  mainContainerStyle="sm:w-[100%] w-full text-[14px]"
                  selectMultiple
                  data={transformSelectOption(documents?.data)}
                  value={selectedDocument}
                  isLoading={documents.isFetching}
                  onChangeOption={(x) => {
                    props.setFieldValue(
                      "supportingDocuments",
                      x.map((eachDoc) => eachDoc?.value),
                    );
                    setSelectedDocument(x);
                  }}
                />

                <p
                  onClick={() => setOpenCreateDoc(true)}
                  className="underline text-[12px] cursor-pointer"
                >
                  Add new documents{" "}
                </p>
              </div>

              <Select
                withLabel
                label="Payment mode"
                mainContainerStyle="sm:w-[100%] w-[30%] text-[14px]"
                className={`${props.errors?.paymentMode && "!border-[red] "}`}
                data={[
                  { value: "offline", label: "Offline" },
                  { value: "online", label: "Online" },
                ]}
                value={selectedPaymentMode}
                onChangeOption={(x) => {
                  props.setFieldValue("paymentMode", x?.value);
                  setSelectedPaymentMode(x);
                }}
              />

              <Input
                className="!w-[30%] sm:!w-[100%] !gap-0"
                withTitle
                title="Application Fee"
                placeholder="Enter amount for application fee"
                name="fee"
                type="number"
              />

              <Input
                className="!w-[30%] sm:!w-[100%] !gap-0"
                withTitle
                title="Start Date"
                name="startDate"
                type="date"
              />

              <Input
                className="!w-[30%] sm:!w-[100%] !gap-0"
                withTitle
                title="End Date"
                name="endDate"
                type="date"
              />

              <Select
                withLabel
                label="Entrance Exam"
                labelClass="sm:text-[12px]"
                dropdownTitleClass="sm:text-[12px]"
                mainContainerStyle="sm:w-[100%] w-[30%] text-[14px]"
                className={`${props.errors?.hasEntranceExam && "!border-[red] "}`}
                optionClass="z-50"
                data={[
                  { value: true, label: "True" },
                  { value: false, label: "False" },
                ]}
                value={selectedHasEntranceExam}
                onChangeOption={(x) => {
                  props.setFieldValue("hasEntranceExam", x?.value);
                  setSelectedHasEntranceExam(x);
                }}
              />
              <Input
                disabled={!props.getFieldProps("hasEntranceExam")?.value}
                className="!w-[30%] sm:!w-[100%] !gap-0"
                withTitle
                title="Entrance Exam Date"
                name="entranceExamDate"
                type="date"
              />

              <Input
                className="!w-[30%] sm:!w-[100%] !gap-0"
                withTitle
                title="Resumption Date"
                name="classResumeDate"
                type="date"
              />

              <InputNoFormik
                disabled
                className="!w-[30%] sm:!w-[100%] !gap-0"
                withTitle
                title="Academic session"
                placeholder="Example : 2023/2024"
                value={session ?? session}
              />

              <div className="flex flex-col w-[30%] sm:w-[100%] gap-1 items-end">
                <Select
                  label="Entry Requirement"
                  className={`${props.errors?.entryRequirements && "!border-[red] "}`}
                  mainContainerStyle="w-[100%] text-[14px]"
                  selectMultiple
                  data={transformSelectOption(
                    entryRequirements?.data,
                    "requirementText",
                  )}
                  value={selectedRequirement}
                  isLoading={entryRequirements.isFetching}
                  onChangeOption={(x) => {
                    props.setFieldValue(
                      "entryRequirements",
                      x.map((eachDoc) => eachDoc?.value),
                    );
                    setSelectedRequirement(x);
                  }}
                />

                <p
                  onClick={() => setOpenCreateEntryRequirement(true)}
                  className="underline text-[12px] cursor-pointer"
                >
                  Add new requirement
                </p>
              </div>

              <Select
                label="Attach Document"
                className={`${props.errors?.attachments && "!border-[red] "}`}
                mainContainerStyle="w-[100%] text-[14px]"
                selectMultiple
                data={transformSelectOption(attachments?.data)}
                value={selectedAttachment}
                isLoading={attachments.isFetching}
                onChangeOption={(x) => {
                  props.setFieldValue(
                    "attachments",
                    x.map((item) => item?.value),
                  );
                  setSelectedAttachment(x);
                }}
              />

              <Input
                className="w-[100%] !gap-0"
                withTitle
                title="Admission Title"
                name="description"
              />

              <Button
                isLoading={applicationState.isLoading}
                className="w-[20%] sm:w-[100%] sm:text-[14px] h-[41px] "
                title="Create"
                handleSubmit={props.handleSubmit}
              />
            </div>
          )}
        </Formik>
      </div>
      <CreateDoc
        openCreateDoc={openCreateDoc}
        setOpenCreateDoc={setOpenCreateDoc}
      />
      <CreateRequirement
        openCreateEntryRequirement={openCreateEntryRequirement}
        setOpenCreateEntryRequirement={setOpenCreateEntryRequirement}
      />
    </MainLayout>
  );
};

export default CreateApplications;
