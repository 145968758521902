import { Route } from "react-router-dom";

import { navigationRoutes } from "Utils/navigationRoutes";
import academicRoutesList from "./academicRoutesList";
import { getRoutes, flattenRoutes } from "Routes/utils";

export const academicRoutes = (
  <Route path={navigationRoutes.SHORTNAME}>
    {getRoutes(academicRoutesList)}
  </Route>
);

export const academicFlattenRoutes = flattenRoutes(academicRoutesList);
