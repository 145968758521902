import { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";

import SidenavRoot from "./SidenavRoot";
import SidenavCollapse from "./SidenavCollapse";
import SidenavItem from "./SidenavItem";
import SidenavList from "./SidenavList";
import useSidebarStore from "./hooks/useSidebarStore";
import cn from "Utils/cn";
import admissionRoutesList from "Routes/admissionRoutes/admissionRoutesList";
import academicRoutesList from "Routes/academicRoutes/academicRoutesList";
import generalRoutesList from "Routes/generalRoutes/generalRoutesList";
import { currentApp } from "Utils/constants";
import useToggleActiveAppStore from "hooks/useToggleActiveAppStore";

const Sidenav = () => {
  const { activeApp } = useToggleActiveAppStore();
  const appRoutes =
    activeApp === currentApp.ADMISSION
      ? admissionRoutesList
      : academicRoutesList;
  const routes = [...appRoutes, ...generalRoutesList];

  const location = useLocation();
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const { miniSidebar } = useSidebarStore();

  const { pathname } = location;
  const shortName = pathname.split("/")[1];
  const appName = pathname.split("/")[2];
  const isAppRoute = [currentApp.ACADEMICS, currentApp.ADMISSION].includes(
    appName,
  );
  const collapseName = pathname.split("/")[isAppRoute ? 3 : 2];
  const items = pathname.split("/").slice(1);
  const itemParentName = items[isAppRoute ? 2 : 1];
  const itemName = items[items.length - 1];

  // TODO: fix SidenavCollapse to remain open when child nav selected and on refresh
  useEffect(() => {
    if (collapseName) {
      setOpenNestedCollapse(collapseName);
    }
  }, [collapseName, openCollapse]);

  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, route, key, href }) =>
      href ? (
        <Link key={key} href={href} target="_blank" rel="noreferrer">
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <Link to={`/${shortName}/${route}`} key={key}>
          <SidenavItem name={name} active={route === pathname} nested />
        </Link>
      ),
    );

    return template;
  };

  const renderCollapse = (collapses) =>
    collapses.map(({ name, collapse, route, href, key }) => {
      let returnValue;

      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            name={name}
            active={key === itemParentName ? "isParent" : false}
            open={openNestedCollapse === key}
            onClick={() =>
              openNestedCollapse === key
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(key)
            }
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link href={href} key={key} target="_blank" rel="noreferrer">
            <SidenavItem name={name} active={key === itemName} />
          </Link>
        ) : (
          <Link to={`/${shortName}/${route}`} key={key}>
            <SidenavItem name={name} active={key === itemName} />
          </Link>
        );
      }
      return returnValue;
    });

  const renderRoutes = routes
    .filter(({ sidenav }) => sidenav)
    .map(
      ({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
        let returnValue;

        if (type === "collapse") {
          if (href) {
            returnValue = (
              <Link href={href} key={key} target="_blank" rel="noreferrer">
                <SidenavCollapse
                  name={name}
                  icon={icon}
                  active={key === collapseName}
                  noCollapse={noCollapse}
                />
              </Link>
            );
          } else if (noCollapse && route) {
            returnValue = (
              <Link to={`/${shortName}/${route}`} key={key}>
                <SidenavCollapse
                  name={name}
                  icon={icon}
                  noCollapse={noCollapse}
                  active={key === collapseName}
                />
              </Link>
            );
          } else {
            returnValue = (
              <SidenavCollapse
                key={key}
                name={name}
                icon={icon}
                active={key === collapseName}
                open={openCollapse === key}
                onClick={() =>
                  openCollapse === key
                    ? setOpenCollapse(false)
                    : setOpenCollapse(key)
                }
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            );
          }
        } else if (type === "title") {
          returnValue = (
            <p
              key={key}
              className={cn(
                "text-xs uppercase font-bold opacity-60 pl-7 mt-4 mb-1 ml-1",
                miniSidebar && "mx-auto pl-0",
              )}
            >
              {title}
            </p>
          );
        } else if (type === "divider") {
          returnValue = (
            <div className="w-4/5 border-t-1 border-black mx-auto my-4" />
          );
        }

        return <SidenavList key={key}>{returnValue}</SidenavList>;
      },
    );
  return (
    <SidenavRoot>
      <SidenavList>{renderRoutes}</SidenavList>
    </SidenavRoot>
  );
};

export default Sidenav;
