/* eslint-disable no-nested-ternary */
import { useState } from "react";
import MainLayout from "Component/Layout/MainLayout";
import Button from "Component/Forms/Button";
import { IoAddCircleOutline } from "react-icons/io5";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate } from "react-router";
import BodyHeaderLayout from "Component/Layout/BodyHeaderLayout";
import { useParams } from "react-router-dom";
import PopOver from "Component/PopOver/PopOver";
import { PopOverList } from "Component/PopOver";
import {
  TableWrapper,
  THead,
  TRow,
  TableLoader,
  TableNoData,
} from "Component/Table";
import {
  useGetClassesQuery,
  useDeleteClassMutation,
} from "Network/classes/class";
import { CircularProgress } from "@mui/material";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import ConfirmDelete from "Component/ConfirmDelete";
import EditClassModal from "../Manage/EditClassModal";
import { toast } from "react-toastify";
import Pagination from "Component/Pagination/Pagination";
import CustomSearch from "Component/CustomSearch";
import usePaginationAndSearch from "Utils/usePaginationAndSearch";
import { generalFlattenRoutes } from "Routes/generalRoutes";

const ClassList = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { page, setPage, search, handleSearch } = usePaginationAndSearch();

  const [selectedItems, setSelectedItems] = useState(null);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const classState = useGetClassesQuery({ page, search });
  const [deleteClass, deleteClassState] = useDeleteClassMutation();

  const handleChangePage = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleDeleteClass = () => {
    if (selectedItems) {
      deleteClass(selectedItems._id)
        .unwrap()
        .then(() => {
          setSelectedItems(null);
          setOpenConfirmDelete(false);
          toast.success("Class deleted successfully");
        })
        .catch((err) => {
          if (err.status === 400) toast.error(err.data?.message);
          else toast.error("Something went wrong");
        });
    }
  };

  return (
    <MainLayout>
      <BodyHeaderLayout title={"Manage Classes"}>
        <div className="flex gap-3 sm:w-[100%]">
          <Button
            className="w-fit sm:w-[50%] text-[12px] h-[41px] "
            title="Add Class"
            Icon={IoAddCircleOutline}
            withIcon
            handleSubmit={() =>
              navigate(
                `/${params.shortName}/${generalFlattenRoutes.schoolClass}`,
              )
            }
          />
        </div>
      </BodyHeaderLayout>

      <div className="flex flex-col gap-5">
        <div className="flex justify-between sm:flex-col mb-[20px] bg-white p-3">
          <CustomSearch
            className="w-[40%] sm:w-[100%] text-[14px]  "
            placeholderText="Search by name, section, class or admission status"
            handleChange={(e) => handleSearch(e.target.value)}
          />
        </div>

        <TableWrapper>
          <table className="w-full ">
            <THead>
              <td className="px-6 py-5 w-[5%]">S/N</td>
              <td className="w-[30%]">Class</td>
              <td className="w-[30%]">Section</td>
              <td className="w-[30%]">Category</td>
              <td className="w-[5%] pr-6">Actions</td>
            </THead>
            {!classState.isFetching ? (
              classState?.data?.data?.length !== 0 ? (
                <tbody>
                  {classState?.data?.data.map((cl, index) => (
                    <TRow
                      key={index}
                      className="odd:bg-[#f7f6f6] even:bg-white text-[12px] hover:bg-blue-100 "
                    >
                      <td className="px-6 py-5">{index + 1}</td>
                      <td>{cl?.name}</td>
                      <td>{cl?.section?.name}</td>
                      <td>{cl?.category?.name || "General"}</td>
                      <td className="pr-6">
                        <PopOver
                          className="w-[100px] text-[12px]"
                          header={<p>...</p>}
                          content={
                            <PopOverList
                              items={[
                                {
                                  name: "Edit",
                                  icon: <FiEdit size={16} />,
                                  onClick: () => {
                                    setSelectedItems(cl);
                                    setOpenEditModal(true);
                                  },
                                },
                                {
                                  name: "Delete",
                                  icon: <RiDeleteBinLine size={16} />,
                                  onClick: () => {
                                    setSelectedItems(cl);
                                    setOpenConfirmDelete(true);
                                  },
                                  style: "text-red-600",
                                },
                              ]}
                            />
                          }
                        />
                      </td>
                    </TRow>
                  ))}
                </tbody>
              ) : (
                <TableNoData />
              )
            ) : (
              <TableLoader />
            )}
          </table>
        </TableWrapper>

        <div className="lg:hidden">
          {!classState.isFetching ? (
            classState?.data?.data?.length !== 0 ? (
              <div className="lg:hidden flex flex-col gap-5 mb-10">
                {classState?.data?.data?.map((cl, index) => (
                  <div className="flex gap-5 px-3 py-2 rounded bg-white  shadow-small-select  text-[14px] text-primary ">
                    <table className="w-full ">
                      <CustomRow title={"Class"} text={cl?.name}>
                        <PopOver
                          className="w-[100px] text-[12px]"
                          header={
                            <BsThreeDotsVertical className="text-[16px]" />
                          }
                          content={
                            <PopOverList
                              items={[
                                {
                                  name: "Edit",
                                  icon: <FiEdit size={16} />,
                                  onClick: () => {
                                    setSelectedItems(cl);
                                    setOpenEditModal(true);
                                  },
                                },
                                {
                                  name: "Delete",
                                  icon: <RiDeleteBinLine size={16} />,
                                  onClick: () => {
                                    setSelectedItems(cl);
                                    setOpenConfirmDelete(true);
                                  },
                                  style: "text-red-600",
                                },
                              ]}
                            />
                          }
                        />
                      </CustomRow>

                      <CustomRow
                        title={"Category"}
                        text={cl?.category?.name || "General"}
                      />

                      <CustomRow
                        title={"Section"}
                        text={cl?.section?.name}
                        withBorder={false}
                      />
                    </table>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-[14px] flex justify-center">No data</div>
            )
          ) : (
            <div className="flex justify-center">
              <CircularProgress />
            </div>
          )}
        </div>
        {classState.isSuccess && classState?.data?.data?.length !== 0 && (
          <div className=" self-end mt-3">
            <Pagination
              onPageChange={handleChangePage}
              pageTotal={classState?.data?.totalPages}
              initialPage={classState?.data?.currentPage}
            />
          </div>
        )}
      </div>

      {openConfirmDelete && (
        <ConfirmDelete
          openConfirmDelete={openConfirmDelete}
          setOpenConfirmDelete={setOpenConfirmDelete}
          title="Class"
          deleteName={selectedItems?.name}
          isLoading={deleteClassState.isLoading}
          handleDelete={handleDeleteClass}
        />
      )}

      {openEditModal && (
        <EditClassModal
          open={openEditModal}
          setOpen={setOpenEditModal}
          data={selectedItems}
        />
      )}
    </MainLayout>
  );
};

export default ClassList;

const CustomRow = ({ title, text, withBorder = true, children }) => {
  return (
    <tr className={`${withBorder && "border-b-1"} w-full`}>
      <td className="py-[8px]">{title}:</td>
      <td className="pl-4 py-[8px]">{text}</td>
      <td className="w-[5%]">{children}</td>
    </tr>
  );
};
