import PropTypes from "prop-types";

function SidenavList({ children }) {
  return <div className="flex flex-col gap-1">{children}</div>;
}

// Typechecking props for the SidenavItem
SidenavList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SidenavList;
