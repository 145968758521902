import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";

import useSidebarStore from "Component/sidenav/hooks/useSidebarStore";

const ToggleSidebar = () => {
  const { miniSidebar, toggleMiniSidebar } = useSidebarStore();

  return (
    <div
      className="sm:hidden text-primary cursor-pointer"
      onClick={() => toggleMiniSidebar()}
    >
      {miniSidebar ? (
        <MdOutlineKeyboardArrowRight size={20} />
      ) : (
        <MdOutlineKeyboardArrowLeft size={20} />
      )}
    </div>
  );
};

export default ToggleSidebar;
