import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";

const CollapseArrow = ({ open }) => {
  return (
    <div className="ml-auto text-xl">
      {open ? (
        <MdOutlineKeyboardArrowUp />
      ) : (
        <MdOutlineKeyboardArrowDown className="text-gray-600" />
      )}
    </div>
  );
};

export default CollapseArrow;
