export const navigationRoutes = {
  SHORTNAME: "/:shortName",
  SIGNUP: "/signup",
  LOGIN: "/login",

  FORGET_PASSWORD: "/forget-password",
  RESET_PASSWORD: "/reset-password",
  VERIFY_EMAIL: "/verifying",
  RESEND_VERIFY_EMAIL: "/resend-verify-email",

  ADMISSION_PAGE: "/admissions",
};
