import { Route } from "react-router-dom";

import { navigationRoutes } from "Utils/navigationRoutes";
import { getRoutes, flattenRoutes } from "Routes/utils";
import generalRoutesList from "./generalRoutesList";

export const generalRoutes = (
  <Route path={navigationRoutes.SHORTNAME}>
    {getRoutes(generalRoutesList)}
  </Route>
);

export const generalFlattenRoutes = flattenRoutes(generalRoutesList);
