import { RxDashboard } from "react-icons/rx";
import { HiOutlineClipboardDocumentCheck } from "react-icons/hi2";
import { FiUsers } from "react-icons/fi";
import { FcDataConfiguration } from "react-icons/fc";

import Dashboard from "Screens/AdmissionScreen/Dashboard";
import Applicant from "Screens/AdmissionScreen/Applicants";
import Applications from "Screens/AdmissionScreen/Applications";
import ApplicantsPaymentVerification from "Screens/AdmissionScreen/ApplicantsPaymentVerification";
import CreateApplicant from "Screens/AdmissionScreen/Applicants/Manage/CreateApplicant";
import CreateApplications from "Screens/AdmissionScreen/Applications/Manage/CreateApplications";
import ApplicantDetails from "Screens/AdmissionScreen/Applicants/View/ApplicantDetails";
import AdmissionTemplate from "Screens/AdmissionScreen/Settings/AdmissionTemplate";
import SupportingDocument from "Screens/AdmissionScreen/Settings/SupportingDocument";
import AdmissionEntryRequirement from "Screens/AdmissionScreen/Settings/AdmissionEntryRequirement";
import ApplicantFormBuilder from "Screens/AdmissionScreen/Settings/ApplicantFormBuilder";
import { Icon } from "Routes/utils/component";
import { currentApp } from "Utils/constants";

const route = currentApp.ADMISSION;

const admissionRoutesList = [
  {
    type: "collapse",
    name: "Dashboard",
    key: `dashboard`,
    route: `${route}/dashboard`,
    icon: <Icon Name={RxDashboard} />,
    noCollapse: true,
    sidenav: true,
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Applicants",
    key: `applicants`,
    route: `${route}/applicants`,
    icon: <Icon Name={FiUsers} />,
    noCollapse: true,
    sidenav: true,
    component: <Applicant />,
  },
  {
    type: "collapse",
    name: "Create Applicants",
    key: `create-applicant`,
    route: `${route}/applicants/create-applicant`,
    icon: <Icon Name={FiUsers} />,
    noCollapse: true,
    component: <CreateApplicant />,
  },
  {
    type: "collapse",
    name: "Applicant Details",
    key: `applicants/:id`,
    route: `${route}/applicants/:id`,
    icon: <Icon Name={FiUsers} />,
    noCollapse: true,
    component: <ApplicantDetails />,
  },
  {
    type: "collapse",
    name: "Applications",
    key: `applications`,
    route: `${route}/applications`,
    icon: <Icon Name={HiOutlineClipboardDocumentCheck} />,
    noCollapse: true,
    sidenav: true,
    component: <Applications />,
  },
  {
    type: "collapse",
    name: "Create Applications",
    key: `create-applications`,
    route: `${route}/applications/create-applications`,
    icon: <Icon Name={HiOutlineClipboardDocumentCheck} />,
    noCollapse: true,
    component: <CreateApplications />,
  },
  {
    type: "collapse",
    name: "Payment Verification",
    key: `payment-verification`,
    route: `${route}/payment-verification`,
    icon: <Icon Name={HiOutlineClipboardDocumentCheck} />,
    noCollapse: true,
    sidenav: true,
    component: <ApplicantsPaymentVerification />,
  },

  {
    type: "collapse",
    name: "Configurations",
    key: "configurations",
    icon: <Icon Name={FcDataConfiguration} />,
    sidenav: true,
    collapse: [
      {
        name: "Admission Template",
        key: "admissions-template",
        route: `${route}/configurations/admissions-template`,
        component: <AdmissionTemplate />,
      },
      {
        name: "Supporting Document",
        key: "supporting-document",
        route: `${route}/configurations/supporting-document`,
        component: <SupportingDocument />,
      },
      {
        name: "Entry Requirement",
        key: "entry-requirement",
        route: `${route}/configurations/entry-requirement`,
        component: <AdmissionEntryRequirement />,
      },

      {
        name: "Applicant Form Builder",
        key: "applicant-form-builder",
        route: `${route}/configurations/applicant-form-builder`,
        component: <ApplicantFormBuilder />,
      },
    ],
  },
];

export default admissionRoutesList;
