import SchoolWebsite from "Screens/SchoolWebpages/Website";
import Admissions from "Screens/SchoolWebpages/Admissions";
import AdmissionPage from "Screens/SchoolWebpages/AdmissionPage";

const schoolWebpagesRoutesList = [
  {
    type: "collapse",
    name: "School website",
    key: "/:shortName",
    route: "/:shortName",
    noCollapse: true,
    component: <SchoolWebsite />,
  },
  {
    type: "collapse",
    name: "School admissions",
    key: "admissions",
    route: "admissions",
    noCollapse: true,
    component: <Admissions />,
  },

  {
    type: "collapse",
    name: "School admission details",
    key: "admissions/:shortName",
    route: "admissions/:shortName",
    noCollapse: true,
    component: <AdmissionPage />,
  },
];

export default schoolWebpagesRoutesList;
