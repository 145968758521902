import { AnimatePresence, motion } from "framer-motion";

const Collapsible = ({ children, open, ...rest }) => {
  return (
    <AnimatePresence initial={false}>
      {open && (
        <motion.div
          key="collapsible-content"
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: "auto", opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
          style={{ overflow: "hidden" }}
          {...rest}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Collapsible;
