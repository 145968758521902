import { useState } from "react";
import { MdCancel } from "react-icons/md";
import { motion } from "framer-motion";

import useSidebarStore from "./hooks/useSidebarStore";
import cn from "Utils/cn";
import useWindowScreen from "Utils/useWindowScreen";

const SidenavRoot = ({ children }) => {
  const { miniSidebar, setMiniSidebar, toggleMiniSidebar } = useSidebarStore();
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  const { windowWidth, isMobile } = useWindowScreen();
  const sidebarMiniWidthCollapse = !isMobile ? 80 : 0;
  const sidebarFullWidth = !isMobile ? 250 : windowWidth;

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidebar && !onMouseEnter) {
      setMiniSidebar(false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidebar(true);
      setOnMouseEnter(false);
    }
  };

  return (
    <div className="fixed h-[100%] z-20 mt-[63px] sm:mt-12 text-sm">
      <motion.div
        className={cn(
          "bg-white h-full pt-10 overflow-y-auto border-t-1 z-20 border-primary",
          miniSidebar && "flex flex-col items-center",
        )}
        initial={{
          width: miniSidebar ? sidebarMiniWidthCollapse : sidebarFullWidth,
          x: miniSidebar ? -220 : 0,
        }}
        animate={{
          width: miniSidebar ? sidebarMiniWidthCollapse : sidebarFullWidth,
          x: 0,
        }}
        transition={{ type: "tween", ease: "easeInOut", duration: 0.4 }}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      >
        <MdCancel
          onClick={(e) => {
            toggleMiniSidebar();
          }}
          size={30}
          className="lg:hidden absolute top-3 right-[2px] cursor-pointer"
        />

        <div>{children}</div>
      </motion.div>
    </div>
  );
};

export default SidenavRoot;
