import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppToast from "./Component/Toaster/AppToast";

import { admissionRoutes } from "./Routes/admissionRoutes";
import { academicRoutes } from "./Routes/academicRoutes";
import { schoolWebpagesRoutes } from "Routes/schoolWebpagesRoutes";
import websiteRoutes from "Routes/websiteRoutes";
import NotFoundPage from "Component/NotFoundPage";
import sharedRoutes from "Routes/sharedRoutes";
import { generalRoutes } from "Routes/generalRoutes";

function App() {
  return (
    <div>
      <AppToast />

      <BrowserRouter>
        <Routes>
          <Route path="*" element={<NotFoundPage />} />
          {sharedRoutes}
          {schoolWebpagesRoutes}
          {generalRoutes}

          {websiteRoutes}
          {admissionRoutes}
          {academicRoutes}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
